import { IoMdImage } from "react-icons/io";
import { TbFileTypePdf } from "react-icons/tb";
import { IoVideocam } from "react-icons/io5";
import { FaMicrophone } from "react-icons/fa";
import { HiOutlinePaperClip } from "react-icons/hi";
import { getFileType } from '../ChatWindowComponents/MessageUtils';
import React from 'react';

export const renderMessageContent = (message) => {
  const urlPattern = /(https?:\/\/[^\s]+)/g;
  const messageParts = message.split(urlPattern);

  return messageParts.map((part, index) => {
    if (urlPattern.test(part)) {
      const fileName = part.substring(part.lastIndexOf('/') + 1);
      const fileType = getFileType(fileName);
      const fileIcon = () => {
        switch (fileType) {
          case 'image':
            return <IoMdImage key={index} />;
          case 'pdf':
            return <TbFileTypePdf key={index} />;
          case 'video':
            return <IoVideocam key={index} />;
          case 'audio':
            return <FaMicrophone key={index} />;
          default:
            return <HiOutlinePaperClip key={index} />;
        }
      };
      return (
        <span key={index}>
          {fileIcon()} {fileName}
        </span>
      );
    } else if (part.startsWith("document/")) {
      const fileUrl = part.replace("document/", "");
      const fileName = fileUrl.substring(fileUrl.lastIndexOf('-') + 1);
      const fileType = getFileType(fileUrl);
      switch (fileType) {
        case 'image':
          return <IoMdImage key={index} />;
        case 'pdf':
          return <TbFileTypePdf key={index} />;
        case 'video':
          return <IoVideocam key={index} />;
        case 'audio':
          return <FaMicrophone key={index} />;
      }
    } else if(part.length > 60){
      return part.slice(0,60) + '...';
    } else {
      return part;
    }
  });
};

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import '../Styles/LoginAndResetPage.css';
import { API_URL } from '../config';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleResetPassword  = async (e) => {
    e.preventDefault();
    if (newPassword && confirmNewPassword && newPassword === confirmNewPassword) {
      if (newPassword.length < 8) {
        toastr.error('Please enter a password with at least 8 characters', 'Error');
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        setIsLoading(true);
        try {
          const response = await axios.post(
            `${API_URL}resetPassword`,
            { password: newPassword },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            }
          );
    
          if (response.status === 200) {
            toastr.success('Your Password has been reset!', 'Success');
            navigate("/");
          } else {
            toastr.error('Failed to reset password', 'Error');
          }
        } catch (error) {
          if (error.response) {
            console.error('Server Error:', error.response.data);
            toastr.error(`Server Error: ${error.response.data.message || 'An error occurred while resetting your password'}`);
          } else if (error.request) {
            console.error('Network Error:', error.request);
            toastr.error('Network Error: No response from server');
          } else {
            console.error('Error:', error.message);
            toastr.error(`Error: ${error.message}`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    } else {
      toastr.error("كلمة المرور لا تطابق التأكيد", "خطأ");
    }
  };

  const backToLogin = () => {
    navigate('/');
  };

  return (
    <div className="page-wrapper-circles">
      <div className="background">
        <div className="shape"></div>
        <form className="submit-form" onSubmit={handleResetPassword }>
          <h3>اعادة تعيين كلمة المرور</h3>
          <label htmlFor="newPassword">كلمة المرور الجديدة:</label>
          <input
            type="password"
            placeholder="ادخل كلمة المرور الجديدة"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            id="newPassword"
          />
          <label htmlFor="confirmNewPassword" className="reset-password-label">
            تأكيد كلمة المرور الجديدة:
          </label>
          <input
            type="password"
            placeholder="ادخل كلمة المرور الجديدة مرة اخرى"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            id="confirmNewPassword"
          />
          <button type="submit" className="primary-button" disabled={isLoading}>
            اعادة تعيين كلمة المرور
          </button>
          <a className="submit-form-link" onClick={backToLogin}>
            الرجوع الى صفحة تسجيل الدخول
          </a>
        </form>
        <ToastContainer />
        <div className="shape"></div>
      </div>
    </div>
  );
};

export default ResetPassword;

import React, { useEffect, useState } from 'react';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const CountryFlag = ({ phoneNumber }) => {
  const [countryCode, setCountryCode] = useState(null);

  useEffect(() => {
    const parsedNumber = phoneNumber ? parsePhoneNumberFromString(phoneNumber) : null;
    if (parsedNumber) {
      setCountryCode(parsedNumber.country);
    } else {
      setCountryCode(null);
    }
  }, [phoneNumber]);

  return (
    <>
      {countryCode && (
        <div className='circular-flag'>
            <span className={countryCode.toLowerCase() === 'il' ? 'fi fi-ps' : `fi fi-${countryCode.toLowerCase()}`} />
        </div>
      )}
    </>
  );
};

export default CountryFlag;

import React, { useState, useRef, useEffect,useCallback } from 'react';
import axios from 'axios';
import { FaMicrophone } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import { BsRecordCircle } from "react-icons/bs";
import { RiSendPlane2Fill } from "react-icons/ri";
import { API_URL } from '../../config';

const AudioRecorder = ({ phone, onRecordingChange }) => {
    const [isRecording, setIsRecording] = useState(false);
    const [isRecordingCanceled, setIsRecordingCanceled] = useState(false);
    const [audioBlob, setAudioBlob] = useState(null);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);

    const startRecording = async () => {
        try {
            setIsRecording(true);
            setIsRecordingCanceled(false);
            setAudioBlob(null);
            onRecordingChange(true);
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const mediaRecorder = new MediaRecorder(stream);
            mediaRecorderRef.current = mediaRecorder;

            audioChunksRef.current = [];

            mediaRecorder.addEventListener('dataavailable', event => {
                audioChunksRef.current.push(event.data);
            });

            mediaRecorder.addEventListener('stop', () => {
                const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mpeg' });
                setAudioBlob(audioBlob);
                audioChunksRef.current = [];
            });

            mediaRecorder.start();
        } catch (error) {
            console.error('Error accessing microphone:', error);
        }
    };

    const cancelRecording = () => {
        setIsRecording(false);
        setIsRecordingCanceled(true);
        onRecordingChange(false);
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }
        audioChunksRef.current = [];
        setAudioBlob(null);
    };

    const stopRecording = () => {
        setIsRecording(false);
        onRecordingChange(false);
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }
    };

    const sendAudioFile = async (audioBlobToSend) => {
        if (!audioBlobToSend || isRecording || isRecordingCanceled) return;

        try {
            const formData = new FormData();
            formData.append('file', audioBlobToSend, 'audio.aac');

            const uploadResponse = await axios.post(`${API_URL}uploadaudio`, formData);

            const uploadResult = uploadResponse.data;

            const sendWhatsappImageResponse = await axios.post(`${API_URL}sendWhatsappImage`, {
                to: phone,
                url: uploadResult.url,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            const sendWhatsappImageResult = sendWhatsappImageResponse.data;
            console.log('Response from sendWhatsappImage:', sendWhatsappImageResult);

            setAudioBlob(null);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        if (!isRecording && audioBlob) {
            sendAudioFile(audioBlob);
        }
    }, [isRecording, audioBlob]);

    useEffect(() => {
        if (!isRecording && audioBlob === null) {
            audioChunksRef.current = [];
        }
    }, [isRecording, audioBlob]);


    return (
        <>
        {isRecording ? (
        <div className="audio-recorder icon-container">
            <RiSendPlane2Fill className="icons-record icon-speed-fast" onClick={stopRecording} />
            <MdOutlineCancel className="icons-record icon-speed-medium" onClick={cancelRecording} />
            <BsRecordCircle className="icons-record icon-recording" />
        </div>
        ) : (
            <div className="audio-start-record">
                <FaMicrophone className="icon icon-start-recording" onClick={startRecording} />
            </div>
            )}
        </>
    );
};

export default AudioRecorder;

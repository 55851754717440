import React, { useState, useEffect, useRef } from 'react';
import { FaPlus } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import { isValidNumber } from 'libphonenumber-js';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import '../../Styles/Buttons.css';
import { API_URL } from '../../config';

const AddContactForm = () => {
  const addContactRef = useRef();
  const [formData, setFormData] = useState({
    showAddContactForm: false,
    name: '',
    phoneNumber: ''
  });

  useEffect(() => {
    const handleClickOutside = event => {
      if (addContactRef.current && !addContactRef.current.contains(event.target)) {
        setFormData(prevState => ({ ...prevState, showAddContactForm: false }));
      }
    };

    const handleEscapeKey = event => {
      if (event.key === 'Escape') {
        setFormData(prevState => ({ ...prevState, showAddContactForm: false }));
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  const handleAddContactClick = () => {
    setFormData(prevState => ({ ...prevState, showAddContactForm: !prevState.showAddContactForm }));
  };

  const handleCancelClick = () => {
    setFormData({ showAddContactForm: false, name: '', phoneNumber: '' });
  };

  const validateAndSaveContact = async () => {
    const { name, phoneNumber } = formData;
    let filteredPhoneNumber = phoneNumber.replace(/[+\s-\u200E]|[^\x00-\x7F]/g, '');
    if (!name.trim()) {
      toast.error('يرجى إدخال الاسم');
      return;
    }

    if (!isValidNumber("+"+filteredPhoneNumber)) {
      toast.error('الرقم غير صحيح');
      return;
    }

    try {
      const response = await axios.post(`${API_URL}createNewContact`, {
        to: filteredPhoneNumber,
        name,
        message: '',
        timestamp: Math.floor(Date.now() / 1000),
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = response.data;
      if (data.status === 'success') {
        toast.success(data.msg);
        setFormData({ showAddContactForm: false, name: '', phoneNumber: '' });
      } else {
        toast.error(data.msg);
      }
    } catch (error) {
      console.error('Error saving contact:', error);
      toast.error('حدث خطأ أثناء حفظ الاتصال');
    }
  };

  return (
    <>
      <ToastContainer />
      {formData.showAddContactForm && <div className="overlay"></div>}
      <div ref={addContactRef}>
        <button className='primary-button add-contact-button' onClick={handleAddContactClick}><FaPlus /></button>
        {formData.showAddContactForm && (
          <div className="add-contact-form">
            <button className="x-button" onClick={handleCancelClick}><IoClose /></button>
            <div className='title-add'>اضافة متصل جديد</div>
            <input
              type="text"
              placeholder="الاسم"
              value={formData.name}
              onChange={e => setFormData({ ...formData, name: e.target.value })}
            />
            <input
              type="tel"
              placeholder="+972-123-456789"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              id="phone"
              name="phone"
              value={formData.phoneNumber}
              onChange={e => setFormData({ ...formData, phoneNumber: e.target.value })}
              className='phone-form'
            />
            <button className='primary-button' onClick={validateAndSaveContact}>حفظ</button>
            <button className='primary-outline-button' onClick={handleCancelClick}>الغاء</button>
          </div>
        )}
      </div>
    </>
  );
};

export default AddContactForm;

import React, { useState } from 'react';
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


const PDFPreview = ({ selectedFile }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function goToNextPage() {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
        }
    }

    function goToPreviousPage() {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    }

    return (
        <div className='pdf-container'>
            <Document file={selectedFile} className='pdf-document' onLoadSuccess={onDocumentLoadSuccess} >
                <Page pageNumber={pageNumber} />
            </Document>
            <div>
                <button onClick={goToPreviousPage} disabled={pageNumber === 1}>الصفحة السابقة</button>
                <button onClick={goToNextPage} disabled={pageNumber === numPages}>الصفحة التالية</button>
            </div>
            <p>
                صفحة {pageNumber} من {numPages}
            </p>
        </div>
    );
};

export default PDFPreview;
import { FaFileLines, FaFilePdf, FaDownload } from "react-icons/fa6";
import { FiDownload } from "react-icons/fi";

export const getFileType = (fileName) => {
  const extension = fileName.split('.').pop().toLowerCase();
  if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp', 'tiff'].includes(extension)) {
    return 'image';
  } else if (['pdf'].includes(extension)) {
    return 'pdf';
  } else if (['mp4', 'avi', 'mkv', 'mov', 'wmv', 'flv', 'webm', 'm4v', '3gp', 'mpeg', 'mpg'].includes(extension)) {
    return 'video';
  } else if (['mp3', 'wav', 'ogg', 'aac', 'm4a', 'wma', 'flac', 'aiff', 'alac'].includes(extension)) {
    return 'audio';
  } else {
    return 'other';
  }
};


export const renderMessageContent = (message , sender) => {
  if (message.startsWith(process.env.REACT_APP_AWS_S3_LINK) || message.startsWith("document/")) {
    const fileUrl = message.replace("document/", "");
    const fileNameOrg = decodeURIComponent(fileUrl.split('-').slice(1).join('-'));
    const fileName = fileNameOrg.split('/')[2]
    const fileType = getFileType(fileUrl);
    switch (fileType) {
      case 'image':
        return <img src={fileUrl} alt="image" className="file-message" onClick={() => window.open(fileUrl, '_blank')} />
      case 'pdf':
        return (
          <div className="file-other">
            <a href={fileUrl} target="_blank" rel="noopener noreferrer" className="file-link pdf-icon">
              <span className="file-name">{fileName}</span> <FaFilePdf />
            </a>
          </div>
        );
      case 'video':
        return (
          <div className="video-container">
            
            {
              sender !== 1 ? (
                <>
                  <a href={fileUrl} download className="download-button" style={{right: '-50px'}}>
                    <FiDownload className="download-icon" />
                  </a>
                  <video src={fileUrl} className="file-message" controls />
                </>
              ) : (
                <>
                  <video src={fileUrl} className="file-message" controls />
                  <a href={fileUrl} download className="download-button">
                    <FiDownload className="download-icon" />
                  </a>
                </>
              )
            }
            
           
          </div>
        );
      case 'audio':
        return <audio src={fileUrl} className="file-message" controls />;
      default:
        return (
          <div className="file-other">
            <a href={fileUrl} target="_blank" rel="noopener noreferrer" className="file-link file-icon">
              <p className="file-name">{fileName}</p>
              <FaFileLines />
            </a>
          </div>
        );
    }
  } else {
    return message;
  }
};

import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate,useParams,useLocation } from 'react-router-dom';

import LoginPage from './LoginPages/LoginPage';
import Header from './Components/HeaderComponents/Header';
import ChatWindow from './Components/ChatWindowComponents/ChatWindow';
import ChatsList from './Components/ChatsListComponents/ChatsList';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import ResetPassword from './LoginPages/ResetPassword';

export const AuthRoute = ({ children }) => {
  const isAuthenticated = checkJWTToken();
  const location = useLocation();

  if (isAuthenticated) {
    const from = location.state?.from?.pathname || '/chat';
    return <Navigate to={from} replace />;
  }

  return children;
};

export function checkJWTToken() {
  const cookies = document.cookie;
  return cookies.includes('jwtuser=') || localStorage.getItem('token') !== null;
}
export const PrivateRoute = ({ children }) => {
  const isAuthenticated = checkJWTToken();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

const App = () => {

  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AuthRoute><LoginPage /></AuthRoute>} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/chat" element={<PrivateRoute><ChatPage /></PrivateRoute>} />
          <Route path="/chat/:phone" element={<PrivateRoute><ChatPage isActive={true} /></PrivateRoute>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
};


const ChatPage = ({isActive}) => (
  <div>
    <Header isActive={isActive} />
    <div className="chat-page-container">
      <ChatWindow isActive={isActive} />
      <ChatsList isActive={!isActive} />
    </div>
  </div>
);

export default App;
